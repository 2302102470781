<template>
  <roughness-template
    :skin-roughness="skinRoughness"
    :skin-roughness-ai-selected="skinRoughnessAiSelected"
    :exfoliate-skin="exfoliateSkin"
    :chemical-peeling="chemicalPeeling"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import RoughnessTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-texture/roughness/RoughnessTemplate';

import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';
import { makeStep, mapAiSelectedFields, resetStoreValues } from '@/modules/questionnaire/mixins';

import { no } from '@/modules/questionnaire/api/helpers';

const { requiredField, field } = fieldBuilder;

const FIELDS_TO_RESET = [
  'textureDiscomfort',
  'firstPatchFlareUps',
  'patchCauses',
  'patches',
  'patchesType',
  'patchLocations'
];

const AI_SELECTED_FIELDS = ['skinRoughness'];
const REQUIRED_FIELDS = ['skinRoughness', 'exfoliateSkin', 'chemicalPeeling'];

const FIELDS = [...REQUIRED_FIELDS.map(requiredField), ...FIELDS_TO_RESET.map(field)];

export default {
  name: 'Roughness',
  components: {
    RoughnessTemplate
  },
  mixins: [makeStep(FIELDS), mapAiSelectedFields(AI_SELECTED_FIELDS), resetStoreValues],
  watch: {
    skinRoughness(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET);
      }

      if (this.exfoliateSkin && this.chemicalPeeling) {
        this.showNextStep();
        return;
      }

      this.scrollTo('#exfoliate-skin');
    },
    exfoliateSkin(newValue, oldValue) {
      if (!oldValue && this.skinRoughness && this.chemicalPeeling) {
        this.showNextStep();
        return;
      }

      this.scrollTo('#chemical-peeling');
    },
    chemicalPeeling(newValue, oldValue) {
      if (!oldValue && this.skinRoughness && this.exfoliateSkin) {
        this.showNextStep();
      }
    }
  }
};
</script>
